<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/tp-min.png"
          width="307"
          height="210"
          alt="Time Pressure Apparatus"
          title="Time Pressure Apparatus"
          class="img-responsive wp-image-12231"
        />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Time-Pressure Apparatus" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) manufactures a Time/Pressure
            test apparatus for clients who require in-house testing to determine
            the effects of igniting a substance under confinement.
          </p>
          <p>
            The Time/Pressure apparatus consists of an instrumented pressure
            vessel equipped with a replaceable rupture disc. A test sample (5
            grams maximum) is placed in the pressure vessel and ignited by flame
            (UN Test 1(c)(i) or 2(c)(i)) or heated Nichrome (NiCr) resistance
            wire (UN Test O.2). Upon ignition, the combustion gases pressurize
            the instrumented test vessel until the rupture disk bursts (~400 psi
            (2760 kPa) gauge).
          </p>
          <p>
            The test results are interpreted in terms of whether a gauge
            pressure of 300 psi (2070 kPa) gauge is reached, and, if so, the
            time taken for the pressure to rise from 100 psig (690 kPa) to 300
            psig (2070 kPa) gauge. The sample has the ability to deflagrate
            rapidly and is an explosive regulated for transport if the pressure
            rise time is less than 30 msec.
          </p>
          <p>
            The following time/pressure profiles are representative of
            instrumented UN Test 2(c)(i) results:
          </p>
          <p>
            Sample A: Fail (2.4 msec rise)<br />
            <img
              src="@/assets/TestEquipment/tpa-min.png"
              alt="tpa"
              width="307"
              height="210"
              class="aligncenter size-full wp-image-12233"
            />
          </p>

          <p>
            Sample B: Pass (52.6 msec rise)<br />
            <img
              src="@/assets/TestEquipment/tpb-min.png"
              alt="tpb"
              width="307"
              height="206"
              class="aligncenter size-full wp-image-12234"
            />
          </p>

          <p>
            Sample C: Pass (34.3 msec rise)<br />
            <img
              src="@/assets/TestEquipment/tpc-min.png"
              alt="tpc"
              width="307"
              height="210"
              class="aligncenter size-full wp-image-12235"
            />
          </p>

          <p>
            The apparatus is supplied with the following (subject to
            availability):
          </p>
          <ul>
            <li>
              A firing plug for Time/Pressure tests (low ignition posts) and
              Oxidizing Liquids tests (high ignition posts)
            </li>
            <li>
              Static (slow rise time) and dynamic (fast rise time) 500-psig
              pressure sensors with associated amplifiers (0 – 5 Volt output)
            </li>
            <li>
              USB-based data acquisition system (DAQ) with pre-configured
              netbook
            </li>
            <li>
              Test supplies: lead washers, rupture discs, NiCr resistance wire
            </li>
            <li>Connection cabling</li>
            <li>Optional: DC regulated power supply and firing line</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "Time Pressure Test Apparatus",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The time-pressure test apparatus is manufacturedo by SMS for UN/DOT testing. The apparatus is manufactured to perform pressure-time testing in several configurations to meet test criteria for several UN Series tests."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `<div style="position: relative;width: 100%;padding-bottom: 56.25%;"><iframe src="https://player.vimeo.com/video/489987380" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>`,
          title: "Sample Video of the Time-Pressure Test"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.about {
}
</style>
